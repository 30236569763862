import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { PageContextProps, PageInfoLinkProps } from '../../types/pages'
import { GatsbyImageBasicProps } from '../../types/images'
import { IIntegration, ITutorialCard } from '../../types/resources'
import { SectionBase } from '../../types/sections'
import { ButtonProps } from '../../types/buttons'
import { PostPreviewWithCategoriesProps } from '../../types/blog'
import { Layout, Seo } from '../../components/commons'
import { SourceTextIllustration, IntegrationHero, IntegrationHowItWorks } from '../../components/resources/integrations'
import LogoList from '../../components/sections/LogoList/LogoList'
import Testimonial from '../../components/sections/Testimonial/Testimonial'
import Push from '../../components/sections/Push/Push'
import Section from '../../components/sections'
import FeaturedArticles from '../../components/sections/FeaturedArticles'
import FeaturedTutorials from '../../components/sections/FeaturedTutorials'

interface SourceQuery {
  source: Pick<
    IIntegration,
    | 'id'
    | 'name'
    | 'pageInfo'
    | 'meta'
    | 'icon'
    | 'excerpt'
    | 'integrationType'
    | 'tags'
    | 'heroButton'
    | 'logos'
    | 'testimonials'
    | 'customSections'
    | 'push'
    | 'howItWorksHeadline'
    | 'howItWorksContent'
    | 'howItWorksButtons'
    | 'howItWorksVideo'
    | 'howItWorksVideoCover'
  >
  utils: {
    sourceLabel: string
    integrationsLanding: PageInfoLinkProps
    sourceDetailsHeadline: string
    sourceDetailsDescription: string
    sourceDetailsIllustration: GatsbyImageBasicProps
    tutorialsIcon: GatsbyImageBasicProps
    tutorialsRelatedLabel: string
    tutorialsAllButton: Array<ButtonProps>
  }
  datoCmsGlobal: {
    blogRelatedLabel: string
    blogAllButton: Array<ButtonProps>
  }
  relatedPosts: { nodes: PostPreviewWithCategoriesProps[] }
  relatedTutorials: { nodes: Array<ITutorialCard> }
}

const Source: React.FC<PageProps<SourceQuery, PageContextProps>> = ({ data, pageContext }) => {
  const { source, utils, relatedTutorials, relatedPosts, datoCmsGlobal } = data

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={data.source.pageInfo.hidePrefooter}
    >
      <Seo
        slug={data.source.pageInfo.slug}
        canonicalUrl={data.source.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={data.source.meta}
        content={data.source.pageInfo.seo}
      />
      <IntegrationHero
        tagLabel={utils.sourceLabel}
        tagLink={utils.integrationsLanding}
        name={source.name}
        integrationType={source.integrationType}
        icon={source.icon}
        tags={source.tags}
        excerpt={source.excerpt}
        heroButton={source.heroButton}
      />
      <IntegrationHowItWorks
        howItWorksHeadline={source.howItWorksHeadline}
        howItWorksButtons={source.howItWorksButtons}
        howItWorksContent={source.howItWorksContent}
        howItWorksVideo={source.howItWorksVideo}
        howItWorksVideoCover={source.howItWorksVideoCover}
      />
      <SourceTextIllustration
        illustration={utils.sourceDetailsIllustration}
        headline={utils.sourceDetailsHeadline}
        description={utils.sourceDetailsDescription}
      />
      {data.relatedPosts.nodes.length > 0 && (
        <FeaturedArticles
          sectionId={''}
          headline={datoCmsGlobal.blogRelatedLabel}
          button={datoCmsGlobal.blogAllButton}
          articles={relatedPosts.nodes}
          id={''}
          model={{ apiKey: 's_featured_articles_list' }}
        />
      )}
      {data.source.logos.length > 0 && <LogoList {...data.source.logos[0]} />}
      {data.source.testimonials.length > 0 && (
        <Testimonial
          id={data.source.testimonials[0].id}
          sectionId={''}
          model={{ apiKey: 's_testimonial' }}
          testimonial={data.source.testimonials[0]}
        />
      )}
      {data.source.customSections &&
        data.source.customSections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}
      {data.relatedTutorials.nodes.length > 0 && (
        <FeaturedTutorials
          sectionId={''}
          icon={utils.tutorialsIcon}
          headline={utils.tutorialsRelatedLabel}
          button={utils.tutorialsAllButton}
          tutorials={relatedTutorials.nodes}
          id={''}
          model={{ apiKey: 's_featured_tutorials_list' }}
        />
      )}
      {data.source.push && <Push id={''} model={{ apiKey: 's_push' }} sectionId={''} push={data.source.push} />}
    </Layout>
  )
}

export default Source

export const query = graphql`
  query SourceQuery($locale: String!, $id: String!, $integrationTags: [String!]!) {
    datoCmsGlobal(locale: $locale) {
      blogRelatedLabel
      blogAllButton {
        ...buttonFragment
      }
      blogContactButton {
        ...buttonFragment
      }
    }

    utils: datoCmsResourcesGlobal(locale: $locale) {
      sourceLabel
      integrationsLanding: integrationsPageInfo {
        ...pageInfoLinkFragment
      }
      sourceDetailsHeadline
      sourceDetailsDescription
      sourceDetailsIllustration {
        alt
        title
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
      tutorialsIcon {
        alt
        title
        gatsbyImageData(layout: CONSTRAINED, width: 80, placeholder: NONE)
      }
      tutorialsRelatedLabel
      tutorialsAllButton {
        ...buttonFragment
      }
    }

    relatedTutorials: allDatoCmsTutorial(
      locale: $locale
      limit: 6
      filter: { id: { ne: $id }, integrations: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        ...tutorialCardFragment
      }
    }

    relatedPosts: allDatoCmsPost(
      locale: $locale
      limit: 3
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: {
        tags: { elemMatch: { id: { in: $integrationTags } } }
        _allContentLocales: { elemMatch: { locale: { eq: $locale } } }
      }
    ) {
      nodes {
        ...postPreviewWithCategoriesFragment
      }
    }

    source: datoCmsIntegration(locale: $locale, id: { eq: $id }) {
      name
      pageInfo {
        ...pageInfoFragment
      }
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      integrationType
      icon {
        alt
        title
        gatsbyImageData
      }
      heroButton {
        ...buttonFragment
      }
      excerpt
      tags {
        id
        identifier
        name
      }
      howItWorksHeadline
      howItWorksButtons {
        ...buttonFragment
      }
      howItWorksContent
      howItWorksVideo {
        url
        providerUid
        provider
        width
        height
      }
      howItWorksVideoCover {
        alt
        title
        gatsbyImageData
      }
      logos {
        ...sectionLogoListFragment
      }
      testimonials {
        ...testimonialFragment
      }
      push {
        ...pushFragment
      }
      customSections {
        ...sectionFeatureSimpleFragment
        ...sectionContentFragment
      }
    }
  }
`
